/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent, MouseEventHandler, useRef, useState } from "react";

import { StrapiNavBar } from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { HalfColumnGaps, Spacing } from "@/tokens/spacing";

import { NestedNavLink } from "@/ui/atoms/nested_nav_link";

import { useMobileMenuExpansionAnimation } from "@/util/animation_hooks/navigation_frame_animations";
import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { backgroundBlur, buildStylesByBreakpoint } from "@/util/style_util";

interface ExpandableNavProps {
    Actions: StrapiNavBar["Actions"];
    className?: SerializedStyles;
    isExpanded: boolean;
    Links: StrapiNavBar["Links"];
    onLinkClick?: MouseEventHandler<HTMLAnchorElement>;
    renderContactSales?: boolean;
}

export const ExpandableNav: FunctionComponent<ExpandableNavProps> = (props) => {
    /**
     * Theme
     */
    const theme = useTypedTheme();

    /**
     * Refs
     */
    const containerRef = useRef(null);

    /**
     * State
     */
    const [activeLinkIndex, setActiveLinkIndex] = useState<number>();

    /**
     * Animations
     */
    useMobileMenuExpansionAnimation(containerRef, props.isExpanded);

    /**
     * Interactions
     */
    const onGroupLinkClick = (index: number) => {
        setActiveLinkIndex(index === activeLinkIndex ? undefined : index);
    };

    /**
     * Styles
     */
    const baseListStyles = css({
        display: "flex",
        flexDirection: "column",
        rowGap: Spacing["spacing-1"],
    });

    const outerContainerStyles = css(
        {
            flexShrink: 1,
            overflow: "hidden",
            pointerEvents: "auto",
            width: "100%",
        },
        buildStylesByBreakpoint("display", {
            extraSmall: "flex",
            medium: "none",
        }),
    );

    const containerStyles = css(
        baseListStyles,
        {
            backdropFilter: backgroundBlur("blurMedium"),
            background: Colors[theme.backgrounds.backgroundSecondary],
            borderRadius: BorderRadiuses.borderLarge,
            boxSizing: "border-box",
            height: 0,
            overflow: "auto",
            pointerEvents: "auto",
            scrollbarWidth: "none",
            width: "100%",
        },
        {
            "&::-webkit-scrollbar": {
                display: "none",
            },
        },
        buildStylesByBreakpoint("padding", HalfColumnGaps),
    );

    const actionStyles = (isContactSales?: boolean) => {
        return css(
            { width: "100%" },
            // We only render contact sales on XS, otherwise it's in main nav
            isContactSales
                ? buildStylesByBreakpoint("display", {
                      extraSmall: "flex",
                      small: "none",
                  })
                : css({ display: "flex" }),
        );
    };

    /**
     * Rendering
     */
    const renderLinks = () => {
        const linksList = props.Links.map((link, index) => {
            const _isPrimitiveLink =
                link.__component === "primitives.primitive-link";

            const navLinkText = _isPrimitiveLink ? link.Text : link.Title;
            const navLinkUrl = _isPrimitiveLink ? link.URL : undefined;
            const navLinkLinks = !_isPrimitiveLink ? link.Links : undefined;

            return (
                <li key={`nav-bar::links-list::link::${link.id}::${index}`}>
                    <NestedNavLink
                        className={css({
                            width: "100%",
                        })}
                        isExpanded={index === activeLinkIndex}
                        isParentExpanded={props.isExpanded}
                        links={navLinkLinks}
                        text={navLinkText}
                        url={navLinkUrl}
                        onClick={
                            !_isPrimitiveLink
                                ? () => onGroupLinkClick(index)
                                : props.onLinkClick
                        }
                        onNestedLinkClick={props.onLinkClick}
                    />
                </li>
            );
        });

        return <ul css={baseListStyles}>{linksList}</ul>;
    };

    const renderActions = () => {
        const actionsList = props.Actions.map((action, index) => {
            const key = `nav-bar::actions-list::action::${action.id}::${index}`;

            switch (action.__component) {
                case "primitives.primitive-link":
                case "primitives.primitive-button": {
                    return (
                        <NestedNavLink
                            className={actionStyles()}
                            key={key}
                            text={action.Text}
                            url={action.URL}
                            onClick={props.onLinkClick}
                        />
                    );
                }
                case "primitives.primitive-download-button": {
                    // We don't render download at all, only in main nav
                    return;
                }
                default: {
                    // Contact Sales CTA
                    return props.renderContactSales ? (
                        <NestedNavLink
                            className={actionStyles(true)}
                            key={key}
                            text={action.Text}
                            url="/contact-sales"
                            onClick={props.onLinkClick}
                        />
                    ) : null;
                }
            }
        });

        return <div css={baseListStyles}>{actionsList}</div>;
    };

    return (
        <div css={outerContainerStyles}>
            <div css={containerStyles} ref={containerRef}>
                {renderLinks()}

                {renderActions()}
            </div>
        </div>
    );
};
