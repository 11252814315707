/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import { LottiePlayer } from "lottie-web";
import { FunctionComponent, useRef } from "react";

import { useLottieFadeAnimation } from "@/util/animation_hooks/lottie_animations";
import { extractFileName } from "@/util/data_util";
import { useIsomorphicLayoutEffect } from "@/util/hooks/effect_hooks";

interface LottieAnimationProps {
    className?: SerializedStyles;
    path: string;
}

export const LottieAnimation: FunctionComponent<LottieAnimationProps> = (
    props,
) => {
    /**
     * Refs
     */
    const animationElementRef = useRef(null);
    const animationNameRef = useRef<string | null>(null);
    const lottieRef = useRef<LottiePlayer | null>(null);

    const [path, setIsLoaded] = useLottieFadeAnimation(
        animationElementRef,
        props.path,
    );

    async function getLottie() {
        const Lottie = await import("lottie-web");
        lottieRef.current = Lottie.default;
    }

    /**
     * Effects
     */
    useIsomorphicLayoutEffect(() => {
        getLottie().then(() => {
            if (animationElementRef.current && lottieRef.current) {
                animationNameRef.current = extractFileName(props.path);

                const animation = lottieRef.current.loadAnimation({
                    autoplay: true,
                    container: animationElementRef.current,
                    loop: true,
                    name: animationNameRef.current,
                    path: props.path,
                    renderer: "svg",
                    rendererSettings: {
                        progressiveLoad: true,
                    },
                });

                animation.addEventListener("data_ready", () => {
                    setIsLoaded(true);
                });
            }
        });
        return () => {
            if (lottieRef.current && animationNameRef.current) {
                lottieRef.current.destroy(animationNameRef.current);
            }
        };
    }, [path]);

    /**
     * Styles
     */
    const animationContainerStyles = {
        display: "flex",
        ...props.className,
    };

    /**
     * Rendering
     */
    return <div css={animationContainerStyles} ref={animationElementRef} />;
};
