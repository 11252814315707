import {
    AppleLogo,
    ArrowDown,
    ArrowLeft,
    ArrowRight,
    ArrowUp,
    ArrowUpRight,
    ArrowsClockwise,
    BookOpen,
    CaretLeft,
    CaretRight,
    ChatsCircle,
    Check,
    CheckCircle,
    Circuitry,
    Clock,
    CloudArrowUp,
    Cpu,
    Database,
    Detective,
    DiamondsFour,
    DiscordLogo,
    DownloadSimple,
    Eye,
    Flag,
    GearSix,
    GithubLogo,
    HandArrowUp,
    HandCoins,
    HandWaving,
    Heart,
    Highlighter,
    Icon,
    Keyboard,
    Laptop,
    Layout,
    Lego,
    Lightning,
    LinkedinLogo,
    LinuxLogo,
    Lock,
    MagnifyingGlass,
    Moon,
    NotePencil,
    OfficeChair,
    Palette,
    Play,
    Plugs,
    Plus,
    PuzzlePiece,
    Question,
    RocketLaunch,
    Rss,
    SidebarSimple,
    Stack,
    SunHorizon,
    TerminalWindow,
    TiktokLogo,
    User,
    UsersThree,
    WarningCircle,
    Waves,
    WindowsLogo,
    X,
    XCircle,
    XLogo,
    YoutubeLogo,
} from "@phosphor-icons/react";

import { StrapiIcon } from "@/types/strapi";

import { SpacingKey } from "@/tokens/spacing";
import { FontSize } from "@/tokens/typography";

import LogoIcon from "@/ui/atoms/logo_icon";

const iconStyleProperties = ["padding", "width"] as const;
export type IconStyleProperty = (typeof iconStyleProperties)[number];

// Button padding
interface IconStyles {
    padding: SpacingKey;
    width: number;
}

/* eslint-disable sort-keys-custom-order/object-keys */
export const iconStyles: {
    [key in FontSize]: IconStyles;
} = {
    TEXT_0625: {
        padding: "spacing-1",
        width: 12,
    },
    TEXT_075: {
        padding: "spacing-1",
        width: 14,
    },
    TEXT_0875: {
        padding: "spacing-1",
        width: 16,
    },
    TEXT_1: {
        padding: "spacing-2",
        width: 18,
    },
    TEXT_1125: {
        padding: "spacing-2",
        width: 20,
    },
    TEXT_125: {
        padding: "spacing-2",
        width: 22,
    },
    TEXT_1375: {
        padding: "spacing-2",
        width: 22,
    },
    TEXT_150: {
        padding: "spacing-2",
        width: 24,
    },
    TEXT_175: {
        padding: "spacing-2",
        width: 28,
    },
    TEXT_2: {
        padding: "spacing-2",
        width: 30,
    },
    // Extending these to avoid type errors
    TEXT_250: {
        padding: "spacing-2",
        width: 30,
    },
    TEXT_3: {
        padding: "spacing-2",
        width: 30,
    },
    TEXT_350: {
        padding: "spacing-2",
        width: 30,
    },
    TEXT_4: {
        padding: "spacing-2",
        width: 30,
    },
    TEXT_5: {
        padding: "spacing-2",
        width: 30,
    },
    TEXT_6: {
        padding: "spacing-2",
        width: 30,
    },
    TEXT_8: {
        padding: "spacing-2",
        width: 30,
    },
    TEXT_10: {
        padding: "spacing-2",
        width: 30,
    },
};
/* eslint-enable sort-keys-custom-order/object-keys */

type FunctionalIcon =
    | "Close"
    | "Play"
    | "Plus"
    | "Lightning"
    | "LinkedIn"
    | "Linux"
    | "Mac"
    | "Windows"
    | "X"
    | "Twitter"
    | "YouTube"
    | "TikTok"
    | "Discord"
    | "GitHub"
    | "RSS"
    | "Arrow Up Right"
    | "Question"
    | "Caret Left"
    | "Caret Right"
    | "RSS";

export type AllIcons = StrapiIcon | FunctionalIcon;

export type StrapiIconMap = {
    [key in AllIcons]:
        | Icon
        | React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>;
};

export const IconConfig: StrapiIconMap = {
    "Arrow Down": ArrowDown,
    "Arrow Left": ArrowLeft,
    "Arrow Right": ArrowRight,
    "Arrow Up": ArrowUp,
    "Arrow Up Right": ArrowUpRight,
    "Caret Left": CaretLeft,
    "Caret Right": CaretRight,
    "Chat Bubbles": ChatsCircle,
    Check: Check,
    "Check Circled": CheckCircle,
    "Circuit Board": Circuitry,
    Clock: Clock,
    Close: X,
    "CPU Chip": Cpu,
    "Database Stack": Database,
    Detective: Detective,
    Diamond: DiamondsFour,
    Discord: DiscordLogo,
    Download: DownloadSimple,
    Eye: Eye,
    Flag: Flag,
    GitHub: GithubLogo,
    "Hand Waving": HandWaving,
    "Hand with Coins": HandCoins,
    "Hand with Up Arrow": HandArrowUp,
    Heart: Heart,
    Highlighter: Highlighter,
    Keyboard: Keyboard,
    Laptop: Laptop,
    Layout: Layout,
    "Lego Block": Lego,
    Lightning: Lightning,
    LinkedIn: LinkedinLogo,
    Linux: LinuxLogo,
    Lock: Lock,
    Mac: AppleLogo,
    "Magnifying Glass": MagnifyingGlass,
    Moon: Moon,
    "Multiple User Avatar": UsersThree,
    "Note and Pencil": NotePencil,
    "Office Chair": OfficeChair,
    "Open Book": BookOpen,
    "Paint Palette": Palette,
    Play: Play,
    Plugs: Plugs,
    Plus: Plus,
    "Puzzle Piece": PuzzlePiece,
    Question: Question,
    Rocket: RocketLaunch,
    RSS: Rss,
    "Settings Gear": GearSix,
    Sidebar: SidebarSimple,
    "Single User Avatar": User,
    Stack: Stack,
    Sunset: SunHorizon,
    "Syncing Arrows": ArrowsClockwise,
    "Terminal Window": TerminalWindow,
    TikTok: TiktokLogo,
    Twitter: XLogo,
    Upload: CloudArrowUp,
    "Warning Circled": WarningCircle,
    Warp: LogoIcon,
    Waves: Waves,
    Windows: WindowsLogo,
    X: XLogo,
    "X Circled": XCircle,
    YouTube: YoutubeLogo,
};
