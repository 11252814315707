import * as _ from "lodash-es";

import {
    FontConfigs,
    FontConfigsBase,
    FontConfigsSupplemental,
    LetterSpacingConfigs,
    LineHeightConfigs,
} from "@/types/tokens/font";

import { convertToRem } from "@/util/ui_util";

/**
 * Font Sizes
 *
 * These are the sizes defined in our design system.
 *
 * Please consult with design before adding additional font sizes.
 *
 */
/* eslint-disable sort-keys-custom-order/object-keys */
const fontSize = {
    TEXT_0625: convertToRem(10),
    TEXT_075: convertToRem(12),
    TEXT_0875: convertToRem(14),
    TEXT_1: convertToRem(16),
    TEXT_1125: convertToRem(18),
    TEXT_125: convertToRem(20),
    TEXT_1375: convertToRem(22),
    TEXT_150: convertToRem(24),
    TEXT_175: convertToRem(28),
    TEXT_2: convertToRem(32),
    TEXT_250: convertToRem(40),
    TEXT_3: convertToRem(48),
    TEXT_350: convertToRem(56),
    TEXT_4: convertToRem(64),
    TEXT_5: convertToRem(80),
    TEXT_6: convertToRem(96),
    TEXT_8: convertToRem(128),
    TEXT_10: convertToRem(160),
} as const;
/* eslint-enable sort-keys-custom-order/object-keys */
export type FontSize = keyof typeof fontSize;

/**
 * Font Weights
 */
/* eslint-disable sort-keys-custom-order/object-keys */
export const fontWeights = {
    normal: "400",
    medium: "500",
};
/* eslint-enable sort-keys-custom-order/object-keys */

export type FontWeight = keyof typeof fontWeights;

/**
 * Line Heights
 */
export enum LegacyLineHeights {
    LINE_HEIGHT_PURE = "1",
    LINE_HEIGHT_PURE_1 = "1.1",
    LINE_HEIGHT_PURE_2 = "1.2",
    LINE_HEIGHT_L_TEXT = "1.25",
    LINE_HEIGHT_M_TEXT = "1.3",
}

export enum LineHeights {
    LINE_HEIGHT_PURE = "1",
    LINE_HEIGHT_XXL_TEXT = "1.05",
    LINE_HEIGHT_XL_TEXT = "1.15",
    LINE_HEIGHT_L_TEXT = "1.2",
    LINE_HEIGHT_M_TEXT = "1.3",
    LINE_HEIGHT_S_TEXT = "1.4",
}

/**
 * Letter Spacing
 *
 * Todo kyleribant: Map to all typography configs
 */
export enum LetterSpacings {
    TECHNICAL_EYEBROW = "0.2em",
}

/**
 * Font Presentation
 *
 * These "presentations" signify the different combinations
 * of line height and letter spacing, depending on if we're
 * using sans serif, code blocks, or eyebrows.
 */
export const fontPresentations = ["sans", "code", "eyebrow"] as const;
export type FontPresentation = (typeof fontPresentations)[number];

/* eslint-disable sort-keys-custom-order/object-keys */
/**
 * Line Height Configs
 */
const LineHeightConfig: LineHeightConfigs = {
    TEXT_0625: {
        lineHeight: LineHeights.LINE_HEIGHT_S_TEXT,
    },
    TEXT_075: {
        lineHeight: LineHeights.LINE_HEIGHT_S_TEXT,
    },
    TEXT_0875: {
        lineHeight: LineHeights.LINE_HEIGHT_S_TEXT,
    },
    TEXT_1: {
        lineHeight: LineHeights.LINE_HEIGHT_S_TEXT,
    },
    TEXT_1125: {
        lineHeight: LineHeights.LINE_HEIGHT_S_TEXT,
    },
    TEXT_125: {
        lineHeight: LineHeights.LINE_HEIGHT_S_TEXT,
    },
    TEXT_1375: {
        lineHeight: LineHeights.LINE_HEIGHT_M_TEXT,
    },
    TEXT_150: {
        lineHeight: LineHeights.LINE_HEIGHT_M_TEXT,
    },
    TEXT_175: {
        lineHeight: LineHeights.LINE_HEIGHT_M_TEXT,
    },
    TEXT_2: {
        lineHeight: LineHeights.LINE_HEIGHT_L_TEXT,
    },
    TEXT_250: {
        lineHeight: LineHeights.LINE_HEIGHT_XL_TEXT,
    },
    TEXT_3: {
        lineHeight: LineHeights.LINE_HEIGHT_XL_TEXT,
    },
    TEXT_350: {
        lineHeight: LineHeights.LINE_HEIGHT_XXL_TEXT,
    },
    TEXT_4: {
        lineHeight: LineHeights.LINE_HEIGHT_XXL_TEXT,
    },
    TEXT_5: {
        lineHeight: LineHeights.LINE_HEIGHT_PURE,
    },
    TEXT_6: {
        lineHeight: LineHeights.LINE_HEIGHT_PURE,
    },
    TEXT_8: {
        lineHeight: LineHeights.LINE_HEIGHT_PURE,
    },
    TEXT_10: {
        lineHeight: LineHeights.LINE_HEIGHT_PURE,
    },
};

/**
 * Letter Spacing Configs
 */
const SansLetterSpacingConfig: LetterSpacingConfigs = {
    TEXT_0625: {
        letterSpacing: ".015em",
    },
    TEXT_075: {
        letterSpacing: ".015em",
    },
    TEXT_0875: {
        letterSpacing: ".01em",
    },
    TEXT_1: {
        letterSpacing: "0.005em",
    },
    TEXT_1125: {
        letterSpacing: "0",
    },
    TEXT_125: {
        letterSpacing: "0",
    },
    TEXT_1375: {
        letterSpacing: "0",
    },
    TEXT_150: {
        letterSpacing: "-.01em",
    },
    TEXT_175: {
        letterSpacing: "-.01em",
    },
    TEXT_2: {
        letterSpacing: "-.01em",
    },
    TEXT_250: {
        letterSpacing: "-.015em",
    },
    TEXT_3: {
        letterSpacing: "-.02em",
    },
    TEXT_350: {
        letterSpacing: "-.02em",
    },
    TEXT_4: {
        letterSpacing: "-.025em",
    },
    TEXT_5: {
        letterSpacing: "-.025em",
    },
    TEXT_6: {
        letterSpacing: "-.025em",
    },
    TEXT_8: {
        letterSpacing: "-.025em",
    },
    TEXT_10: {
        letterSpacing: "-.03em",
    },
};

const MonoLetterSpacingConfig: LetterSpacingConfigs = {
    TEXT_0625: {
        letterSpacing: ".05em",
    },
    TEXT_075: {
        letterSpacing: ".05em",
    },
    TEXT_0875: {
        letterSpacing: ".05em",
    },
    TEXT_1: {
        letterSpacing: ".05em",
    },
    TEXT_1125: {
        letterSpacing: ".05em",
    },
    TEXT_125: {
        letterSpacing: ".05em",
    },
    TEXT_1375: {
        letterSpacing: ".05em",
    },
    TEXT_150: {
        letterSpacing: ".05em",
    },
    TEXT_175: {
        letterSpacing: ".05em",
    },
    TEXT_2: {
        letterSpacing: ".025em",
    },
    TEXT_250: {
        letterSpacing: ".025em",
    },
    TEXT_3: {
        letterSpacing: ".025em",
    },
    TEXT_350: {
        letterSpacing: "-.01em",
    },
    TEXT_4: {
        letterSpacing: ".025em",
    },
    TEXT_5: {
        letterSpacing: ".025em",
    },
    TEXT_6: {
        letterSpacing: ".025em",
    },
    TEXT_8: {
        letterSpacing: "0",
    },
    TEXT_10: {
        letterSpacing: "0",
    },
};
/* eslint-enable sort-keys-custom-order/object-keys */

/**
 * Font Config Exports
 */
const SansFontSupplementalConfigs: FontConfigsSupplemental = _.merge(
    SansLetterSpacingConfig,
    LineHeightConfig,
);

const MonoFontSupplementalConfigs: FontConfigsSupplemental = _.merge(
    MonoLetterSpacingConfig,
    LineHeightConfig,
);

const EyebrowFontSupplementalConfigs: FontConfigsSupplemental = _.mapValues(
    fontSize,
    () => ({
        letterSpacing: LetterSpacings.TECHNICAL_EYEBROW,
        lineHeight: LineHeights.LINE_HEIGHT_PURE,
    }),
);

/**
 * This generates the size configs for sans, code, and eyebrow presentations.
 */
export const FontSizeConfigs: { [key in FontPresentation]: FontConfigs } = {
    code: mergeSizeConfigs(MonoFontSupplementalConfigs),
    eyebrow: mergeSizeConfigs(EyebrowFontSupplementalConfigs),
    sans: mergeSizeConfigs(SansFontSupplementalConfigs),
};

/**
 * This merges the custom configs with the base font size config.
 *
 * @param supplementalConfigs
 * @returns
 */
function mergeSizeConfigs(
    supplementalConfigs: FontConfigsSupplemental,
): FontConfigs {
    const generateFontConfigBase = (): FontConfigsBase => {
        return _.mapValues(fontSize, (_value, _key) => ({
            fontSize: fontSize[_key as FontSize],
        }));
    };

    return _.merge(generateFontConfigBase(), supplementalConfigs);
}
