/** @jsxImportSource @emotion/react */
import { CustomAppProps } from "@/pages/_app";
import { Global, ThemeProvider, css } from "@emotion/react";
import cssReset from "emotion-reset";
import { FunctionComponent } from "react";

import { SingleTheme } from "@/types/tokens/themes";

import { Colors } from "@/tokens/color";
import { BaseUnit } from "@/tokens/globals";
import { MasterTheme } from "@/tokens/themes";

import { DownloadPostFooter } from "@/ui/organisms/download_post_footer";
import { Footer } from "@/ui/organisms/footer";
import { LegacyPreFooter } from "@/ui/organisms/legacy_pre_footer";
import { MinimalFooter } from "@/ui/organisms/minimal_footer";
import { NavigationFrame } from "@/ui/organisms/navigation_frame";
import { PreFooter } from "@/ui/organisms/pre_footer";

interface LayoutProps extends CustomAppProps {
    children: React.ReactNode;
}

export const Layout: FunctionComponent<LayoutProps> = ({
    children,
    renderMinimalNav = false,
    ...props
}) => {
    /**
     * Elements
     */
    /**
     * New pages after 10/2024 use the new pre-footer component,
     * but we still need to provide support for the legacy pages
     * that either use a legacy pre-footer or none at all.
     */
    const renderPreFooter = () => {
        if (props.prefooter) {
            return <PreFooter {...props.prefooter} />;
        }

        if (props.legacyPrefooter) {
            return (
                <LegacyPreFooter
                    {...props.legacyPrefooter}
                    onlyRenderDownloadCta={renderMinimalNav}
                />
            );
        }

        return;
    };

    /**
     * We render the minimal footer for ad campaigns, which is opted
     * in via the Minimal_Nav field from Strapi.
     */
    const renderFooter = () => {
        return renderMinimalNav ? (
            <MinimalFooter legacyGrid={props.legacyGrid} />
        ) : (
            <Footer
                {...props.globalsData.footer}
                legacyGrid={props.legacyGrid}
                renderDivider={!props.prefooter}
            />
        );
    };

    return (
        <ThemeProvider theme={MasterTheme}>
            <Global
                styles={(theme) => {
                    const _castedTheme = theme as SingleTheme;

                    return css(cssReset, {
                        "html, body": {
                            background:
                                Colors[
                                    _castedTheme.backgrounds.backgroundPrimary
                                ],
                            fontSize: `${BaseUnit}px`,
                        },
                    });
                }}
            />

            <NavigationFrame
                {...props.globalsData}
                breadcrumbs={props.breadcrumbs}
                previewMode={props.previewMode}
                renderContactSales={props.renderContactSales}
                renderDemo={props.renderDemo}
                renderMinimalNav={renderMinimalNav}
                renderNotifications={props.renderNotifications}
            />

            <main>{children}</main>

            {renderPreFooter()}

            {renderFooter()}

            {props.renderPostFooter && (
                <DownloadPostFooter
                    downloadGroups={props.globalsData.downloads}
                    legacyGrid={props.legacyGrid}
                />
            )}
        </ThemeProvider>
    );
};
