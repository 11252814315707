import { ReactNode } from "react";

export interface ButtonData {
    children: ReactNode;
    href?: string;
    newWindow?: boolean;
}

export interface LinkData {
    children: ReactNode;
    href: string;
    newWindow?: boolean;
}

export interface MetaData {
    canonicalUrl: string;
    description: string;
    faviconUrl: string;
    openGraphUrl: string;
    pageTitle?: string;
    pageUrl: string;
    path?: string;
    shouldIndex: boolean;
    siteTitle: string;
}

export interface GeneralPageLevelProps {
    path?: string;
    previewMode?: boolean;
    slug: string;
}

export type TableOfContents = Array<{
    slug: string;
    text: string;
}>;

export const BreadcrumbLevels = ["level1", "level2", "level3"] as const;
export type BreadcrumbLevel = (typeof BreadcrumbLevels)[number];
export type Breadcrumbs = { [key in BreadcrumbLevel]?: LinkData };

/**
 * Typesafe OS keys
 *
 * Pulled from https://github.com/duskload/react-device-detect/blob/master/src/constants/constants.js
 */
export const SupportedOperatingSystemKeys = [
    "mac",
    "windows",
    "linux",
] as const;
const SupplementalOperatingSystemKeys = ["iOS", "android"] as const;
export const OperatingSystemKeys = [
    ...SupportedOperatingSystemKeys,
    ...SupplementalOperatingSystemKeys,
] as const;
export type OperatingSystem = (typeof OperatingSystemKeys)[number];

// Current Warp-supported OS
export type SupportedOperatingSystem =
    (typeof SupportedOperatingSystemKeys)[number];

export const BrowserKeys = [
    "chrome",
    "firefox",
    "safari",
    "opera",
    "edge",
    "ie",
] as const;
export type Browser = (typeof BrowserKeys)[number];
