/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import * as _ from "lodash-es";
import { FunctionComponent } from "react";

import { StrapiCta, StrapiCtaDynamicZone } from "@/types/strapi";
import { ElementAlignment } from "@/types/ui";

import { spacingSets } from "@/tokens/configs/spacing_config";
import { FontStyleSlug } from "@/tokens/configs/typography_config";
import { Spacing } from "@/tokens/spacing";

import { Button } from "@/ui/atoms/button";
import { CodeCta } from "@/ui/atoms/code_cta";
import { Link } from "@/ui/atoms/link";
import { DownloadButtons } from "@/ui/molecules/download_buttons";

import { getCodeCtaTrack } from "@/util/code_cta_util";
import { useGlobalsContext } from "@/util/context/globals_context";
import {
    buildStylesByBreakpoint,
    buildStylesByStringOrObject,
} from "@/util/style_util";
import { RenderLocation } from "@/util/tokens/render_location_util";
import { TrackEvent } from "@/util/tokens/track_event_util";
import { convertToRem } from "@/util/ui_util";

import { NestedForm } from "./nested_form";

interface CtaGroupProps {
    alignment?: ElementAlignment;
    className?: SerializedStyles;
    ctaData: StrapiCta;
    /**
     * Todo kyleribant: Remove hard-coded contact sales cta and
     * power it by Strapi instead. Once that's done, remove all logic
     * surrounding onlyRenderDownloadCta as the logic culminates here
     */
    onlyRenderDownloadCta?: boolean;
    primaryFontSize?: FontStyleSlug;
    renderLocation?: RenderLocation;
    secondaryFontSize?: FontStyleSlug;
}

export const CtaGroup: FunctionComponent<CtaGroupProps> = ({
    alignment = "center",
    renderLocation = RenderLocation.HERO,
    ...props
}) => {
    /**
     * Globals
     */
    const ctaData = _.compact([
        props.ctaData.Primary_Action,
        props.ctaData.Secondary_Action,
    ]);

    /**
     * Context
     */
    const { isTouchDevice, operatingSystem } = useGlobalsContext();

    /**
     * Styles
     */
    const containerStyles = css(
        {
            display: "flex",
            flexDirection: "column",
            width: "100%",
        },
        buildStylesByStringOrObject("alignItems", alignment),
        buildStylesByBreakpoint("columnGap", spacingSets.CtaGroupColumn),
        buildStylesByBreakpoint("rowGap", spacingSets.CtaGroupRow),
        props.className,
    );

    const ctaSubGroupStyles = css(
        {
            alignItems: "center",
            display: "flex",
            flexDirection: operatingSystem === "mac" ? "row" : "column",
            flexWrap: "wrap",
            gap: Spacing["spacing-4"],
            width: "100%",
        },
        // Mac is the only variant that aligns to a row
        operatingSystem !== "mac"
            ? buildStylesByStringOrObject("alignItems", alignment)
            : undefined,
        buildStylesByStringOrObject("justifyContent", alignment),
    );

    const nestedFormStyles = css({
        maxWidth: convertToRem(460),
    });

    /**
     * Elements
     */
    const renderCtaByType = (
        cta: StrapiCtaDynamicZone,
        parentIndex: number,
        index: number,
    ) => {
        const _componentId = cta.__component;
        const _key = `cta-group::${_componentId}::${parentIndex}-${index}`;
        const _isPrimary = parentIndex === 0;

        const _fontSize = _isPrimary
            ? props.primaryFontSize
            : props.secondaryFontSize;

        switch (_componentId) {
            case "primitives.primitive-link":
                return (
                    <Link
                        fontSize={_fontSize}
                        href={cta.URL}
                        key={_key}
                        noWrap={true}
                    >
                        {cta.Text}
                    </Link>
                );
            case "primitives.primitive-download-button": {
                if (isTouchDevice) {
                    return (
                        <NestedForm
                            alignment={alignment}
                            className={nestedFormStyles}
                            formEndpoint={
                                process.env.NEXT_PUBLIC_SEND_DOWNLOAD_ENDPOINT!
                            }
                            formName="Mobile Download Form"
                            key={_key}
                            placeholder="Enter your email"
                            submitText="Send link"
                            title="On a mobile device? Send Warp to your work station."
                        />
                    );
                }

                if (operatingSystem === "windows") {
                    return (
                        <NestedForm
                            alignment={alignment}
                            className={nestedFormStyles}
                            formEndpoint={
                                process.env
                                    .NEXT_PUBLIC_WINDOWS_WAITLIST_ENDPOINT!
                            }
                            formName="windows waitlist"
                            key={_key}
                            placeholder="Enter your email"
                            submitText="Sign up"
                            title="Join the Windows waitlist"
                        />
                    );
                }

                return (
                    <DownloadButtons
                        fontSize={_fontSize}
                        key={_key}
                        renderLocation={renderLocation}
                        renderSecondaryText={operatingSystem === "linux"}
                    />
                );
            }
            case "primitives.primitive-code-cta":
                return operatingSystem === "mac" && !isTouchDevice ? (
                    <CodeCta
                        code={cta.Code}
                        fontSize={_fontSize}
                        key={_key}
                        track={getCodeCtaTrack(cta.Code)}
                    />
                ) : undefined;
            case "components.component-waitlist-form":
                return (
                    <NestedForm
                        formEndpoint={cta.Submission_URL}
                        formName="windows waitlist"
                        key={_key}
                        placeholder={cta.Placeholder_Text}
                        submitText={cta.CTA_Text}
                        title={cta.Title}
                    />
                );
            case "primitives.primitive-sales-button":
                return isTouchDevice || props.onlyRenderDownloadCta ? null : (
                    <Link
                        fontSize={_fontSize}
                        href="/contact-sales"
                        interactionType="hover-color-change"
                        key={_key}
                        track={TrackEvent.REQUEST_DEMO_LINK}
                    >
                        {cta.Text}
                    </Link>
                );
            default:
                return (
                    <Button
                        fontSize={_fontSize}
                        href={cta.URL}
                        key={_key}
                        variant="primary"
                    >
                        {cta.Text}
                    </Button>
                );
        }
    };

    return (
        <div css={containerStyles}>
            {ctaData.map((_actionByPosition, _parentIndex) => (
                <div
                    css={ctaSubGroupStyles}
                    key={`cta-parent-group::${_parentIndex}`}
                >
                    {_actionByPosition.map((_actionsWithinPosition, _index) =>
                        renderCtaByType(
                            _actionsWithinPosition,
                            _parentIndex,
                            _index,
                        ),
                    )}
                </div>
            ))}
        </div>
    );
};
